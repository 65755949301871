import React from 'react';
import annuncio1 from '../../data/img/circle.png'
function Tab2() {
    return (
        <div class="tab-pane fade contentTabPink" id="tab-2" role="tabpanel" aria-labelledby="tab-2">
        <div class="container mt-0 ">

                <div class="col-lg-auto text-center mb-4">
                    <h3> Accetta le sfide dei tuoi amici o scegli quella giusta per te! </h3>
                </div>
                <div class="row">
                    {/* Icon Cards Pane */}
                    <div class="col-lg-12">
                        <div class="card left-pane">
                            <div class="row">
                            <div class="col-lg-4">
                                <img class="img-fluid" src={annuncio1} alt="alternative" width="150" />
                            </div>
                            <div class="col-lg-auto mt-auto">
                                <div class="card-body">
                                    <div class="text-wrapper ">
                                        <h4 class="card-title"><i class="fas fa-camera-retro"></i> Registra</h4>
                                        <p >Registra un breve video in cui spieghi in cosa consiste la tua sfida! Sii breve e conciso!
                                                </p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 mt-2">
                        <div class="card left-pane">
                            <div class="row">
                            <div class="col-lg-4">
                                <img class="img-fluid" src={annuncio1} alt="alternative" width="150" />
                            </div>
                            <div class="col-lg-auto mt-auto">
                                <div class="card-body">
                                    <div class="text-wrapper ">
                                        <h4 class="card-title"><i class="fas fa-camera-retro"></i> Registra</h4>
                                        <p >Registra un breve video in cui spieghi in cosa consiste la tua sfida! Sii breve e conciso!
                                                </p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 mt-2">
                        <div class="card left-pane">
                            <div class="row">
                            <div class="col-lg-4 mt-auto">
                                <img class="img-fluid" src={annuncio1} alt="alternative" width="150" />
                            </div>
                            <div class="col-lg-auto">
                                <div class="card-body">
                                    <div class="text-wrapper ">
                                        <h4 class="card-title"><i class="fas fa-camera-retro"></i> Registra</h4>
                                        <p >Registra un breve video in cui spieghi in cosa consiste la tua sfida! Sii breve e conciso!
                                                </p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                  </div>{/* end of row */}
            </div> {/* end of container */}
        </div> 
    )
}
export default Tab2