import React from 'react';
import annuncio1 from '../../data/img/circle.png'
function Tab3() {
    return (
        <div class="tab-pane fade" id="tab-3" role="tabpanel" aria-labelledby="tab-3">
            <div class="container">
                <div class="row">

                    {/* Text And Icon Cards Area */}
                    <div class="col-md-8">
                        <div class="icon-cards-area">
                            <div class="card">
                                <div class="card-icon">
                                    <i class="far fa-calendar-check"></i>
                                </div>
                                <div class="card-body">
                                    <h4 class="card-title">Calendar Input</h4>
                                    <p>Schedule your appointments, meetings and periodical evaluations using the provided in-app calendar option</p>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-icon">
                                    <i class="far fa-file-code"></i>
                                </div>
                                <div class="card-body">
                                    <h4 class="card-title">Visual Editor</h4>
                                    <p>Leno provides a well designed and ergonomic visual editor for you to edit your notes and input data</p>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-icon">
                                    <i class="fas fa-cube"></i>
                                </div>
                                <div class="card-body">
                                    <h4 class="card-title">Good Foundation</h4>
                                    <p>Get a solid foundation for your self development efforts. Try Leno mobile app for any mobile platform</p>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-icon">
                                    <i class="far fa-bookmark"></i>
                                </div>
                                <div class="card-body">
                                    <h4 class="card-title">Easy Reading</h4>
                                    <p>Reading focus mode for long form articles, ebooks and other materials which involve large text areas</p>
                                </div>
                            </div>
                        </div> {/* end of icon cards area */}

                        <div class="text-area">
                            <h3>Monitoring Tools Evaluation</h3>
                            <p>Monitor the evolution of your finances and health state using tools integrated in Leno. The generated real time reports can be filtered based on any <a class="turquoise" href="#your-link">desired criteria</a>.</p>
                        </div> {/* end of text-area */}
                    </div> {/* end of col-md-8 */}
                    {/* end of text and icon cards area */}

                    {/* Image Pane */}
                    <div class="col-md-4">
                        <img class="img-fluid" src="images/features-iphone-3.png" alt="alternative" />
                    </div>
                    {/* end of image pane */}

                </div> {/* end of row */}
            </div>
        </div>)}
        export default Tab3;