import React from 'react'

import Tab1 from './tabFeatures/Tab1';
import Tab2 from './tabFeatures/Tab2';
import Tab3 from './tabFeatures/Tab3';

const sottotitolo = <span className="font-large">Versus è il nuovo <strong>social network </strong> basato sulle sfide.<br />Metti alla prova i tuoi amici!</span>;
function Features() {
    return (
        <div id="features" class="tabs">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h2>Come funziona</h2>
                        <p class="p-large">{sottotitolo}</p>
                    </div> {/* end of col */}
                </div> {/* end of row */}
                <div class="row">
                    {/* Tabs Links */}
                    <ul class="nav nav-tabs" id="lenoTabs" role="tablist">                      
                        <li class="nav-item pl-2">
                         <a class="nav-link  btnGreen active" id="nav-tab-1" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true"><i class="fas fa-bullhorn"></i>Crea</a>
                        </li>
                        
                       
                        <li class="nav-item pl-2">
                            <a class="nav-link  btnPink" id="nav-tab-2 " data-toggle="tab" href="#tab-2" role="tab" aria-controls="tab-2" aria-selected="false"><i class="fas fa-users"></i>Partecipa</a>
                        </li>
                        <li class="nav-item pl-2">
                            <a class="nav-link  btnRed" id="nav-tab-3" data-toggle="tab" href="#tab-3" role="tab" aria-controls="tab-3" aria-selected="false"><i class="fas fa-star"></i>Vota </a>
                        </li>
                    </ul>
                    {/* Tabs Content*/}
                    <div class="tab-content" id="lenoTabsContent">
                        {/* Tab - Crea un annuncio*/}
                        <Tab1/>
                        {/* Tab - Partecipa ad una sfida*/}
                        <Tab2/>
                        {/* Tab - Vota il tuo preferito */}
                       <Tab3/>
                        {/* end of tab */}
                    </div> 
                </div>
            </div> 
        </div>)
}
export default Features;