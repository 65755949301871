import React from 'react';
{/* Header */ }
function Header() {
    return (<header id="header" class="header">
        <div class="header-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="image-container">
                            <img class="img-fluid" src="images/data/illustration-header.png" alt="alternative" />
                        </div> {/* end of image-container */}
                    </div>
                    
                        <div class="container-fluid">

                            <h3>Sfida i tuoi amici e fatti votare dal grande pubblico!<br/>
Scaricalo oggi!</h3><br/>
                            <a class="btn-solid-lg page-scroll" href="#your-link"><i class="fab fa-apple"></i>APP STORE</a>
                            <a class="btn-solid-lg page-scroll" href="#your-link"><i class="fab fa-google-play"></i>PLAY STORE</a>
                        </div>
                    </div> {/* end of col */}
                    {/* end of col */}
                </div> {/* end of row */}
            </div> {/* end of container */}
        {/* end of header-content */}
    </header>
    )
}
export default Header;