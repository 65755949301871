import React from 'react';

function Loader(){
    return <div class="spinner-wrapper">
    <div class="spinner">
    <div class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>
    </div>
</div>
}
export default Loader;