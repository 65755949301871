import React, { Fragment } from 'react';
import Navigation from './layout/Navigation';
import Loader from './component/Loader';
import Header from './layout/Header';
import Testimonial from './component/Testimonial';
import Features from './component/Features';
import Video from './component/Video';
import Details from './component/details/Details';
import Footer from './layout/Footer';

function App() {
  return (
  <Fragment>
    <Loader />
    <Navigation />
    <Header />
    <Features />
    <Testimonial />
    <Video />
    <Details />
    <Footer />
  </Fragment>
  )
}
export default App;
