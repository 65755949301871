import React from 'react';
import annuncio1 from '../../data/img/circle.png'
function Tab1() {
    return (
        <div class="tab-pane fade show active " id="tab-1" role="tabpanel" aria-labelledby="tab-1">

            <div class="container mt-0">
                <div class="row">

                    {/* Text And Icon Cards Area */}
                    <div class="col-md-8">
                        <div class="icon-cards-area">
                            <div class="card">

                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="card-icon back-green">
                                            <i class="fas fa-camera-retro">
                                                </i>
                                            </div>
                                        </div>
                                        <div class="col-8 text-justify-left">
                                            <h4 class="card-title text-green">Step 1 > Registra</h4>
                                            <p>Crea la tua sfida e registra il tuo annuncio, fai un breve video in cui spieghi in cosa consiste la tua sfida! Sii breve e conciso!</p>

                                        </div>
                                    </div>
                                </div>

                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="card-icon back-green">
                                            <i class="fas fa-palette">
                                                </i>
                                            </div>
                                        </div>
                                        <div class="col-8 text-justify-left">
                                            <h4 class="card-title text-green">Step 2 > Editor video</h4>
                                            <p>A scelta, edita il video del tuo annuncio, con effetti, stickers e tanto altro!</p>

                                        </div>
                                    </div>
                                </div>

                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="card-icon back-green">
                                            <i class="fas fa-tasks">
                                                </i>
                                            </div>
                                        </div>
                                        <div class="col-8 text-justify-left">
                                            <h4 class="card-title text-green">Step 3 > Scegli la modalità</h4>
                                            <p>La sfida accetta fino a 4 partecipanti! Scegli tra i tuoi amici o
                                        lascia la sfida aperta per far aggiungere altri giocatori volontariamente. Scegli i temi che la caratterizzano aggiungendo  gli hashtag!
                                        Ah e non ti dimenticare di dare un nome alla tua sfida!</p>

                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="card-icon back-green">
                                            <i class="fas fa-trophy">
                                                </i>
                                            </div>
                                        </div>
                                        <div class="col-8 text-justify-left">
                                            <h4 class="card-title text-green">Final step > Lancia la sfida!</h4>
                                            <p>Dal momento in cui tutti i partecipanti avranno accettato e caricato il loro video controsfida, avrete <strong>un'ora</strong> per accaparrarvi il titolo da vincitore!</p>

                                        </div>
                                    </div>
                                </div>

                            </div>      
                        </div> 
                    </div> 

                    {/* Image Pane */}
                    <div class="col-md-4">
                        <img class="img-fluid" src="images/features-iphone-3.png" alt="alternative" />
                    </div>
                    {/* end of image pane */}

                </div> {/* end of row */}
            </div>
        </div>
    )
}
export default Tab1;