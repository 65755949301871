import React from 'react'

function Video() {
    return (

        <div id="preview" class="basic-1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h2>PREVIEW</h2>
                        <div class="p-heading p-large">Target the right customers for your business with the help of Leno's patented segmentation technology</div>
                    </div> {/* end of col */}
                </div> {/* end of row */}
                <div class="row">
                    <div class="col-lg-12">

                        {/* Video Preview */}
                        <div class="image-container">
                            <div class="video-wrapper">
                                <a class="popup-youtube" href="https://www.youtube.com/watch?v=fLCjQJCekTs" data-effect="fadeIn">
                                    <img class="img-fluid" src="images/video-frame.jpg" alt="alternative" />
                                    <span class="video-play-button">
                                        <span></span>
                                    </span>
                                </a>
                            </div> {/* end of video-wrapper */}
                        </div> {/* end of image-container */}
                        {/* end of video preview */}

                    </div> {/* end of col */}
                </div> {/* end of row */}
            </div> {/* end of container */}
        </div>
    )
}
export default Video;